/**
 * Copyright 2017 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.firebaseui-container {
  background: none;
  padding: 16px;
  border-style: none;  
  box-shadow: none;
}
.firebaseui-container.firebaseui-id-page-sign-in {
  background: none;
  padding: 0;
  border-style: none;  
  box-shadow: none;
}
.firebaseui-container.firebaseui-id-page-sign-in.mdl-card {
  min-height: '10px';
}

.firebaseui-container.firebaseui-page-provider-sign-in {
  background: transparent;
  box-shadow: none;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}
.firebaseui-container.firebaseui-id-page-callback {
  background: transparent;
  box-shadow: none;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}
/* .firebaseui-card-header {
  display: none;
} */
.firebaseui-subtitle,
.firebaseui-text {
  color: rgba(255, 255, 255, 0.87);
}
.firebaseui-form-actions
  .mdl-button--raised.mdl-button--colored.firebaseui-button {
  background: #fdb913
}
.firebaseui-id-dismiss-info-bar {
  display: block;
}
.firebaseui-info-bar {
  border: 0;
  border-radius: 10px;
  left: 5%;
  right: 5%;
  top: 10%;
  bottom: 10%;
}
